<template>
    <a-layout class="adminlayout">
        <a-layout-header style="padding: 0 24px">
            <div class="logo">
                <img src="../assets/logo.png" alt="轻舟" />
                <span>储能方舟</span>
            </div>
            <div class="title">轻舟能科综合管理后台</div>
            <div class="right">
                <a-dropdown>
                    <a
                        class="ant-dropdown-link"
                        @click="(e) => e.preventDefault()"
                    >
                        {{ userName }} <a-icon type="down" />
                    </a>
                    <a-menu slot="overlay" @click="dropdownClick">
                        <a-menu-item key="manage">
                            <a href="javascript:;">
                                {{
                                    $route.fullPath.startsWith("/admin")
                                        ? "前台管理"
                                        : "后台管理"
                                }}
                            </a>
                        </a-menu-item>
                        <a-menu-item key="personal">
                            <a href="javascript:;">个人中心</a>
                        </a-menu-item>
                        <a-menu-item key="logout">
                            <a href="javascript:;">退出登录</a>
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>
            </div>
        </a-layout-header>
        <a-layout>
            <a-layout-sider>
                <a-menu
                    mode="inline"
                    theme="dark"
                    :inline-collapsed="collapsed"
                    v-model="selectedKeys"
                    @select="selectMenu"
                >
                    <template v-for="item in menuList">
                        <a-menu-item
                            v-if="!item.children || item.meta.hiddenMenu"
                            :key="item.name"
                        >
                            <a-icon :type="item.meta.icon || 'pie-chart'" />
                            <span>{{ item.meta.title }}</span>
                        </a-menu-item>
                        <sub-menu v-else :key="item.name" :menu-info="item" />
                    </template>
                </a-menu>
            </a-layout-sider>
            <a-layout-content>
                <router-view class="content" />
                <page-footer></page-footer>
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>

<script>
import PageFooter from "@/components/PageFooter";
import SubMenu from "@/components/SubMenu";
import { Modal } from "ant-design-vue";
import { loginOut } from "@/api/index";

export default {
    name: "AdminLayout",
    components: {
        PageFooter,
        SubMenu,
    },
    data() {
        return {
            userName: "",
            // 展开收起
            collapsed: true,
            // 当前选中菜单项 string[]
            selectedKeys: [],
            // 侧边导航数据源: 不包含/admin
            menuList: [],
        };
    },
    watch: {
        $route(to, from) {
            let toFullPath = to.fullPath,
                fromFullPth = from.fullPath;
            let flag = 0;
            for (let path of [toFullPath, fromFullPth]) {
                if (path.startsWith("/admin")) flag += 1;
            }
            if (flag === 1) this.setMenu();
        },
    },
    created() {
        this.userAuth = JSON.parse(localStorage.getItem("_userAuth") || "[]");
        this.setMenu();
    },
    mounted() {
        let userInfo = JSON.parse(localStorage.getItem("_userInfo") || "{}");
        this.userName = userInfo && userInfo.userName;
    },
    methods: {
        // 扁平化数组
        checkAuth(target) {
            let is_auth = false;
            for (let auth of this.userAuth) {
                if (auth.type === target.name) {
                    is_auth = true;
                    break;
                } else {
                    is_auth = false;
                }
            }
            return is_auth;
        },

        // 设置侧边导航
        setMenu() {
            let result = [];
            let menuList = [];

            let flag = this.$route.fullPath.startsWith("/admin")
                ? "back"
                : "front";
            this.selectedKeys = [];
            // 进入后台管理
            if (flag === "back") {
                if (this.$route.name === "personal") {
                    this.selectedKeys = [];
                }
                result = require("@/router/back.router").default[0].children;
            } else {
                result = require("@/router/front.router").default;
            }

            result.forEach((item) => {
                if (!item.child) {
                    this.checkAuth(item) && menuList.push(item);
                } else {
                    let parent = {
                        ...item,
                        children: [],
                    };
                    for (let child of item.children) {
                        this.checkAuth(child) && parent.children.push(child);
                    }
                    parent.children.length && menuList.push(parent);
                }
            });

            this.menuList = menuList;

            this.$nextTick(() => {
                this.selectedKeys = [
                    this.$route.matched[flag === "front" ? 1 : 2].name,
                ];
            });
        },
        // 选中菜单
        selectMenu({ key }) {
            this.selectedKeys = [key];
            this.$router.push({ name: key });
        },
        // header 下拉菜单点击
        dropdownClick({ key }) {
            if (key === "manage") {
                const userAuth = this.userAuth;

                let temp = [];
                if (this.$route.fullPath.startsWith("/admin")) {
                    temp = require("@/router/front.router").default;
                } else {
                    temp = require("@/router/back.router").default[0][
                        "children"
                    ];
                }

                for (let item of temp) {
                    if (userAuth.some((auth) => auth.type == item.name)) {
                        return this.$router.push({ name: item.name });
                    }
                }
                this.$message.info("没有访问权限");
                return;
            } else if (key === "personal") {
                this.personal();
            } else {
                this.logout();
            }
        },
        // 个人中心
        personal() {
            this.selectedKeys = [];
            this.$router.push({ name: "personal" });
        },
        // 退出
        logout() {
            Modal.confirm({
                title: "是否退出登录",
                okText: "确定",
                cancelText: "取消",
                onOk: () => {
                    let data = {};
                    loginOut(data).then((res) => {
                        localStorage.clear();
                        this.$router.push({ name: "login" });
                        this.$message.success(res.msg);
                    });
                },
                onCancel() {},
            });
        },
    },
};
</script>

<style lang="scss">
.adminlayout {
    .ant-layout {
        height: calc(100vh - 64px);
    }
    .ant-layout-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #000d0f;
        color: #ffffff;
        .logo {
            img {
                width: 40px;
                height: 40px;
                object-fit: contain;
                margin-right: 8px;
            }
            span {
                font-size: 20px;
                font-weight: 600;
            }
        }
        .title {
            font-size: 26px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 600;
        }
        .right {
            padding: 0 5px;
            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }
    }
    .ant-layout-sider,
    .ant-menu-dark {
        background-color: #002329;
    }
    .ant-layout-content {
        overflow-y: auto;
        padding: 24px;
        .content {
            min-width: 1000px;
            min-height: 600px;
            padding: 24px;
            background-color: #fff;
            .page_title {
                font-size: 16px;
                color: rgba(0, 0, 0, 0.85);
                font-weight: bold;
            }

            .ant-table-thead > tr > th,
            .ant-table-tbody > tr > td {
                padding: 16px 10px;
            }
        }
    }
}
</style>
